<template>
  <PromiseContainer :promises.sync="promises">
    <template v-slot:default>
      <PageLinkHeader :router-link="userCalendarPage" :link-text="'Terug naar Kalender van ' + getUserName" :header="'Dagoverzicht van ' + getUserName"/>
      <DayOverview class="mt-5" :userId="userId" :date="date" :edit-absence-route="editAbsenceRoute" :edit-hours-route="editHoursRoute" :return-route="returnRoute" :navRoute="navRoute"/>
    </template>
  </PromiseContainer>
</template>

<script>
import DayOverview from "@/views/DayOverview.vue";
import RouteNames from "@/router/RouteNames";
import PageLinkHeader from "@/components/shared/PageLinkHeader";
import PromiseContainer from "../shared/PromiseContainer.vue";

export default {
  name: "UserDayOverview",
  components: {
    DayOverview,
    PageLinkHeader,
    PromiseContainer
  },
  props: {
    userId: {
      type: String,
      required: true
    },
    date: {
      type: String,
      required: true
    },
    returnRoute: {
      type: Object,
      required: false,
      default:() => ({name: RouteNames.MODIFY_USER_HOURS}),
    },
    editHoursRoute:{
      type: Object,
      required: false,
    },
    editAbsenceRoute:{
      type: Object,
      required: false,
    },
    navRoute:{
      type: Object,
      required: false,
    }
  },
  data() {
    return {
      userName: {},
      userCalendarPage: {name: this.returnRoute.name, params: {userId: this.userId, date: this.date}},
      promises: [
        this.$store.dispatch('usersModule/fetchUserName', this.userId)
        .then(data => this.userName = data)
      ]
    }
  },
  computed: {
    getUserName(){
      if (!this.userName?.firstName || !this.userName?.lastName) {
        return ''
      }
      return `${this.userName.firstName} ${this.userName.lastName}`
    }
  },
}
</script>

